import React, {useState} from 'react';
import styled, {css} from 'styled-components';
import {TextField, Button, boldBrandFont, brandFont} from '@partssourceinc/react-ui-core';
import axios from 'axios';
import {validateEmail, validatePhoneNumber} from '/validation';
import HubSpotSubmitRequest from 'components/HubSpotSubmitRequest';
import {theme} from '@partssourceinc/react-ui-core';

const Wrapper = styled.div`
    max-width: 495px;
    padding: 48px 0 96px 0;
    margin: 0 auto; 
`;

const TopWrapper = styled.div`
    text-align: center;
`;

const IconHolder = styled.div`
    margin-bottom: 16px;    

    img {
        vertical-align: middle;
    }

    @media (max-width: ${theme.screenXsMax}) {
        display: none;
    }
`;

const Title = styled.h1`
    margin-bottom: 16px;
`;

const Subtitle = styled.div `
    font-size: 16px;
    margin-bottom: 30px;
`;

const OptionsSection = styled.div`
    ${brandFont('16px')} 
    border-top: 1px solid #ccc;
    padding-top: 18px;

    > p {
        margin-bottom: 16px;
    }
`;

const Interests = styled.div `  
   padding-bottom: 20px;
   display: flex;
   flex-wrap: wrap;
`;

const InterestsItem = styled.input`
    position: absolute;
    left: -9999px;
`;

const InterestsLabel = styled.label`
    position: relative;
    cursor: pointer;
    height: 34px;
    line-height: 32px;
    display: inline-block;
    color: #4a4a4a;
    ${brandFont('16px')}
    padding: 0 10px;
    border: 2px solid #dcdcdc;
    border-radius: 3px;
    margin: 0 8px 8px 0;

    ${props => props.selected && css`
        border-color: #ff9505;
    `}
`;

const SignInWrapper = styled.div `
    text-align: center;
    margin-top: 16px;
    font-size: 16px;

    span {
        margin-right: 5px;
    }

    a {
        ${boldBrandFont('16px')}
        color: #005da6;
    }
`;

const RegistrationForm = styled.form`
    display: flex;
    flex-wrap: wrap;
`;

const TextBox = styled(TextField)`
    padding-left: 4px;
    padding-right: 4px;
    margin-bottom: 16px;
    margin-top: 0;

    span {
        ${boldBrandFont('10px')};
    }

    ${props => props.multiLine && css`
        > div {
            height: 200px;
        }
    `}

    @media (max-width: 505px) {
        flex: 0 0 100%;
        max-width: 100%;

        ${props => props.fieldType === 'number' && css`
            flex: 0 0 47%;
            max-width: 47%;
        `}
    }

    ${props => props.fieldType === 'number' && css`
        min-width: 130px;  
    `}
`;

const ButtonBar = styled.div`
    padding: 16px 0 24px 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0 5px;
    position: relative;    
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;

    @media (max-width: ${theme.screenXsMax}) {
        button {        
            width: 355px;
            max-width: 100%;
        }

        justify-content: center;
    }
`;

const HubSpotRegistration = () => {
    const [firstName, setFirstName] = useState('');
    const [firstNameTouched, setFirstNameTouched] = useState(false);
    const [lastName, setLastName] = useState('');
    const [lastNameTouched, setLastNameTouched] = useState(false);
    const [email, setEmail] = useState('');
    const [emailTouched, setEmailTouched] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneNumberTouched, setPhoneNumberTouched] = useState(false);
    const [extension, setExtension] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [companyNameTouched, setCompanyNameTouched] = useState(false);
    const [website, setWebsite] = useState('');
    const [description, setDescription] = useState('');
    const [invalidPhoneNumber, setInvalidPhoneNumber] = useState(false);
    const [invalidEmail, setInvalidEmail] = useState(false);
    const [viewResponse, setViewResponse] = useState(false);
    const [interests, setInterests] = useState([]);

    const disableSubmit = !firstName || !lastName || !email || !phoneNumber || !companyName 
        || !validateEmail(email) || !validatePhoneNumber(phoneNumber)

    const submitForm = async () => {
        const contactUsValues = {
            'fields': [
                {
                    'name': 'firstname',
                    'value': firstName,
                },
                {
                    'name': 'lastname',
                    'value': lastName,
                },
                {
                    'name': 'email',
                    'value': email,
                },
                {
                    'name': 'phone',
                    'value': phoneNumber,
                },
                {
                    'name': 'phone_number_extension',
                    'value': extension,
                },
                {
                    'name': 'company',
                    'value': companyName,
                },
                {
                    'name': 'website',
                    'value': website,
                },
                {
                    'name': 'company_description',
                    'value': description,
                },
                {
                    'name': 'interests_contact_form',
                    'value': interests.join(';'),
                },
            ],
        }

        await axios.post(`${process.env.REACT_APP_HUBSPOT_URL}/submissions/v3/integration/submit/413818/7c4e9477-151d-4a77-b21c-548a0928f621`, contactUsValues)
            .then(res => {
                setViewResponse(true);
            })
            .catch(err => {
                console.log('Hubspot Registration error')
                console.log(err)
            })
    }

    const emailChange = (e) => {
        setEmail(e.target.value)
        if (e.target.value && !validateEmail(e.target.value)) 
            setInvalidEmail(true);
        else
            setInvalidEmail(false);
              
    } 

    const phoneNumberChange = (e) => {
        setPhoneNumber(e.target.value);
        
        if (e.target.value && !validatePhoneNumber(e.target.value))
            setInvalidPhoneNumber(true);
        else
            setInvalidPhoneNumber(false);
    }

    const handleInterestChange = (e) => {
        if (interests.indexOf(e.target.name) === -1)
            setInterests([...interests, e.target.name])
        else {
            setInterests(interests.filter(item => item !== e.target.name));
        }
    }

    return (
        <Wrapper>
            {!viewResponse && 
            <div> 
                <TopWrapper>
                    <IconHolder>
                        <img src={`/images/media_guid.png`} />
                    </IconHolder>
                    <Title>Thank you for your interest</Title>
                    <Subtitle>{`Please fill out the information below along with your areas of interest so we can have someone get back to you as soon as possible.`}</Subtitle>
                </TopWrapper>
                <RegistrationForm onSubmit={submitForm}>
                    <TextBox
                        label="First Name"
                        name="firstName"
                        placeholder="First Name"
                        text={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        showErrorMessage={firstNameTouched && !firstName}
                        errorMessage="Please complete this required field."
                        onBlur={() => setFirstNameTouched(true)}
                        className="col-5"
                    /><TextBox
                        label="Last Name"
                        name="lastName"
                        placeholder="Last Name"
                        text={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        onBlur={() => setLastNameTouched(true)}
                        showErrorMessage={lastNameTouched && !lastName}
                        errorMessage="Please complete this required field."
                        className="col-7"
                    /><TextBox
                        label="Business Email Address"
                        name="email"
                        placeholder="Business Email Address"
                        text={email}
                        onChange={(e) => emailChange(e)}
                        onBlur={() => setEmailTouched(true)}
                        showErrorMessage={emailTouched && (!email || invalidEmail)}
                        errorMessage= {invalidEmail ? 'Please enter a valid email address' : 'Please complete this required field.'}
                        className="col-8"
                    /><TextBox
                        label="Phone Number"
                        name="phone"
                        placeholder="Phone Number"
                        text={phoneNumber}
                        onChange={(e) => phoneNumberChange(e)}
                        onBlur={() => setPhoneNumberTouched(true)}
                        showErrorMessage={phoneNumberTouched && (!phoneNumber || invalidPhoneNumber)}
                        errorMessage= {invalidPhoneNumber ? 'Please enter a valid phone number' : 'Please complete this required field.'}
                        className="col-6"
                    /><TextBox
                        label="Ext. (optional)"
                        name="extension"
                        placeholder="Ext. (optional)"
                        text={extension}
                        type="number"
                        onChange={(e) => setExtension(e.target.value)}
                        errorMessage="Please complete this required field."
                        className="col-3"
                        fieldType="number"
                    /><TextBox
                        label="Company Name"
                        name="companyName"
                        placeholder="Company Name"
                        text={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                        onBlur={() => setCompanyNameTouched(true)}
                        showErrorMessage={companyNameTouched && !companyName}
                        errorMessage="Please complete this required field."
                        className="col-8"
                    /><TextBox
                        label="Company Website (optional)"
                        name="website"
                        placeholder="Company Website (optional)"
                        text={website}
                        onChange={(e) => setWebsite(e.target.value)}
                        errorMessage="Please complete this required field."
                        className="col-12"
                    /><TextBox
                        label="Company description and Speciality (optional)"
                        name="description"
                        placeholder="Company description and Speciality (optional)"
                        text={description}
                        onChange={(e) => setDescription(e.target.value)}
                        multiLine={true}

                        errorMessage="Please complete this required fi
                    eld."
                        className="col-12"
                    />
                </RegistrationForm>
                <OptionsSection>
                    <p>Interests (select all that apply)</p>
                    <Interests>                        
                        <InterestsLabel 
                            selected={interests.indexOf('Sell parts and equipment') > -1} 
                            htmlFor="sellParts">
                            Sell parts and equipment
                        </InterestsLabel>
                        <InterestsItem 
                            name="Sell parts and equipment" 
                            id="sellParts" 
                            label="Sell parts and equipment" 
                            checked={interests.indexOf('Sell parts and equipment') > -1} 
                            onClick={(e) => handleInterestChange(e)} />
                        <InterestsLabel 
                            selected={interests.indexOf('Join the PartsSource Service Network') > -1} 
                            htmlFor="joinServiceNetwork">
                            Join the PartsSource Service Network
                        </InterestsLabel>
                        <InterestsItem 
                            name="Join the PartsSource Service Network" 
                            id="joinServiceNetwork" 
                            label="Join the PartsSource Service Network" 
                            checked={interests.indexOf('Join the PartsSource Service Network') > -1} 
                            onClick={(e) => handleInterestChange(e)} />
                        <InterestsLabel 
                            selected={interests.indexOf('Advertising') > -1} 
                            htmlFor="advertising">
                            Advertising
                        </InterestsLabel>
                        <InterestsItem 
                            name="Advertising" 
                            id="advertising" 
                            label="Advertising" 
                            checked={interests.indexOf('Advertising') > -1} 
                            onClick={(e) => handleInterestChange(e)} />
                        <InterestsLabel 
                            selected={interests.indexOf('White Label') > -1} 
                            htmlFor="whiteLabel">
                            White Label
                        </InterestsLabel>
                        <InterestsItem 
                            name="White Label" 
                            id="whiteLabel" 
                            label="White Label" 
                            checked={interests.indexOf('White Label') > -1} 
                            onClick={(e) => handleInterestChange(e)} />
                        <InterestsLabel 
                            selected={interests.indexOf('Performance Analytics') > -1} 
                            htmlFor="performanceAnalisys">
                            Performance Analytics
                        </InterestsLabel>
                        <InterestsItem 
                            name="Performance Analytics" 
                            id="performanceAnalisys" 
                            label="Performance Analytics" 
                            checked={interests.indexOf('Performance Analytics') > -1} 
                            onClick={(e) => handleInterestChange(e)} />                         
                    </Interests>     
                </OptionsSection>
                <ButtonBar>
                    <Button disabled={disableSubmit} primary={true} tabIndex={4} type="submit" onClick={submitForm}>
                    Submit
                    </Button>
                </ButtonBar>
                <SignInWrapper>
                    <span>
                    Already have an account?
                    </span>
                    <a data-ea-exit-link="Supplier Sign In" href="https://supplier.partssource.com/login" target="_blank" rel=" noopener noreferrer">Sign-In</a>
                </SignInWrapper>
            </div>}
            {viewResponse && <div>
                <HubSpotSubmitRequest />
            </div>}
        </Wrapper>
    )
}

export default HubSpotRegistration;
